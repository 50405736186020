
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiPlusCircleOutline, mdiRun, mdiAccount, mdiFlagOutline, mdiUpdate, mdiSoccer, mdiSitemap, mdiBulldozer, mdiSchool, mdiCalendar, mdiHeadCog } from '@mdi/js';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import BALogoAdminSvg from '../svg/logos/BALogoAdminSvg.vue';
import { Route } from 'vue-router';
import * as AdminRoutes from '../../../types/constants/admin.routes';
import EventsNavItems from './EventsNavItems.vue';

@Component({
	components: {
		BALogoAdminSvg,
		EventsNavItems,
		SideNavSectionTitle,
	},
})
export default class AdminSideNav extends Vue{
	mini = false;

	iconTeam = mdiSoccer;
	iconOrganization = mdiSitemap;
	iconUser = mdiAccount;
	iconSchool = mdiSchool;
	iconEvents = mdiCalendar;
	iconPhysical = mdiRun;
	iconPersonality = mdiHeadCog;

	mdiBulldozer = mdiBulldozer;
	mdiPlusCircleOutline = mdiPlusCircleOutline;
	mdiFlagOutline = mdiFlagOutline;
	mdiUpdate = mdiUpdate;

	@Prop({ default: true, type: Boolean }) value: boolean;
	@Prop({ default: 'baColorAdminPrimary' }) color: string;

	get Color(): string{
		return this.color;
	}

	get TeamAdminDashboard(): Partial<Route>{
		return {
			name: AdminRoutes.TeamAdminDashboard,
		}
	}

	get OrganizationAdminDashboard(): Partial<Route>{
		return {
			name: AdminRoutes.OrganizationAdminDashboard,
		}
	}
	get SchoolSearchAdminDashboard(): Partial<Route>{
		return {
			name: AdminRoutes.SchoolSearchAdminDashboard,
		}
	}
	get CreateSchoolRoute(): Partial<Route>{
		return {
			name: AdminRoutes.SchoolCreate,
		}
	}

	
}
